import * as React from "react"
import * as style from "./FeaturesSectionItemComponent.module.scss";

interface FeaturesSectionCardProps
{
    icon: string;
    name: string;
}

export function FeaturesSectionItemComponent({icon, name}: FeaturesSectionCardProps)
{
    const Svg = require(`../assets/icons/${icon}.svg`).default;

    return (
        <li className={style.container}>
            <Svg />
            <span>{name}</span>
        </li>
    );
}