import * as React from "react"
import * as style from "./FeaturesSectionCardComponent.module.scss";

interface FeaturesSectionCardProps
{
    icon: string;
    title: string;
    description: string;
}

export function FeaturesSectionCardComponent({icon, title, description}: FeaturesSectionCardProps)
{
    const Svg = require(`../assets/icons/${icon}.svg`).default;

    return (
        <li className={style.container}>
            <div className={style.iconContainer}>
                <Svg />
            </div>
            <h3>{title}</h3>
            <p>{description}</p>
        </li>
    );
}