import * as React from "react";

const Spline = React.lazy(() => import("@splinetool/react-spline"));

export default function SplineElement()
{
    return (
        <div style={{
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            overflow: "hidden"
        }}>
            {/*<Spline scene="https://draft.spline.design/0TV-Mok5wx5RK9cO/scene.spline" />*/}
            {/*<Spline scene="https://draft.spline.design/bUB0C-Z5hyWvCW65/scene.spline" />*/}
            {/*<Spline scene={"/scene.spline"} />*/}
            {/*<Spline scene={"https://prod.spline.design/FKhuayd57Lqyv1jL/scene.spline"} />*/}
            {/*<Spline scene={"https://draft.spline.design/uEestbTlIZX-LGBF/scene.spline"} />*/}
            {/* <Spline scene={"https://draft.spline.design/RgDWhKwwPywPzEF9/scene.spline"} /> */}

            {/* <Spline scene={"https://prod.spline.design/rslc2ShF81tpHegW/scene.splinecode"} /> */}
            <Spline scene={"/scene.splinecode"} />
        </div>
    );
}