import * as React from "react"
import * as style from "./FeaturesSectionComponent.module.scss";

export enum FeaturesSectionType
{
    CARD,
    ITEM
}

interface FeaturesSectionCardProps
{
    children: any;
    id: string;
    title: string;
    description: string;
    type?: FeaturesSectionType;
}

export function FeaturesSectionComponent({children, id, title, description, type}: FeaturesSectionCardProps)
{
    return (
        <section id={id} className={style.container}>
            <h2 className={style.title}>{title}</h2>
            <p className={style.description}>{description}</p>
            <ul className={
                type === FeaturesSectionType.ITEM ? style.items : style.cards
            }>
                {children}
            </ul>
        </section>
    );
}