import * as React from "react"
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import { FeaturesSectionCardComponent } from "../components/FeaturesSectionCardComponent";
import { FeaturesSectionItemComponent } from "../components/FeaturesSectionItemComponent";
import { FeaturesSectionComponent, FeaturesSectionType } from "../components/FeaturesSectionComponent";
import SplineElement from "../components/SplineElement";
import Element from "../components/Element";
import useMediaQuery from "../hooks/useMediaQuery";

const Keywords = require("../data/keywords.json");

const MAIL = "hi@ido.land";
const HEADER = "We can take your idea and put it in the cloud with an awesome user experience";
const isSSR = typeof window === "undefined"
// const isMobile = !isSSR && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const isMobile = false; // TODO:

const LazySplineElement = () =>
{
    return (
        <>
            {!isSSR && !isMobile && (
                <React.Suspense fallback={<div />}>
                    <SplineElement />
                </React.Suspense>
            )}
        </>
    )
}

// TODO: Add learn more for each section
const features = require("../data/features.json");

// https://worldvectorlogo.com/
// https://icons8.com/icons/set/flutter
// https://www.vectorlogo.zone/logos/mongodb/index.html
// https://github.com/wappalyzer/wappalyzer/tree/master/src/drivers/webextension/images/icons
// https://www.iloveimg.com/resize-image/resize-svg#resize-options,pixels
const technologies = require("../data/technologies.json");

const IndexPage = () =>
{
    const [ active, setActive ] = useState(false);
    const [ spline, setSpline ] = useState(false);

    const companies = 
    [
        require(`../assets/companies/autodesk.svg`).default,
        require(`../assets/companies/jfrog.svg`).default,
        require(`../assets/companies/simspace.png`).default,
        require(`../assets/companies/varonis.svg`).default,
    ];

    // const isMobile = useMediaQuery("(max-width: 700px)");
    const isMobile = false; // TODO:

    !isSSR && !isMobile && useEffect(() =>
    {
        const onScroll = () =>
        {
            if (window.scrollY < 450 && active)
                setActive(false);
            else if (window.scrollY >= 450 && !active)
                setActive(true);
        };

        window.addEventListener("scroll", onScroll);

        return () =>
        {
            window.removeEventListener("scroll", onScroll);
        };
    }, [active]);

    return (
        <>
            <Helmet
                title="ido.land"
                htmlAttributes={{
                    lang: "en",
                }}
                meta={[
                    {
                        name: `description`,
                        content: Keywords.home.join(", "),
                    },
                    {
                        name: "keywords",
                        content:
                        [
                            ...Keywords.home,
                            ...technologies.map((technology: any) => technology.name),
                            ...["פיתוח"].map(label => technologies.map((technology: any) => `${label} ${technology.name}`).join(", "))
                        ].join(", "),
                    },
                ]}
            />
            <header>
                <nav>
                    <ul>
                        <li><img className="logo" src={require("../images/logo.png").default} /></li>
                        <li><a href="/#services">Services</a></li>
                        <li><a href="/#technologies">Technologies</a></li>
                        <li><a href="/#customers">Customers</a></li>
                        <li><a href="/#about">About</a></li>
                    </ul>
                    <ul>
                        <a className={["button", ...active ? ["active"] : []].join(" ")} href="/#contact">Contact</a>
                    </ul>
                </nav>
            </header>
            <main>
                {
                    !isMobile ? (
                        <>
                            <section className={"spline"}>
                                <div className="spline-content">
                                    {/*<h1>Your search is over here, we can take your idea and put it in the cloud with an awesome user experience</h1>*/}
                                    <h1>{HEADER}</h1>
                                </div>
                                {/*<div className="spline-label">*/}
                                {/*    Press and drag to orbit*/}
                                {/*</div>*/}
                                <div className="spline-view">
                                    <div className={"spline-container"}>
                                        {
                                            !spline ? (
                                                <>
                                                <Element />
                                                <div onClick={() => setSpline(true)} style={{
                                                    position: "relative",
                                                    left: 0,
                                                    right: 0,
                                                    margin: "auto",
                                                    top: "75%",
                                                    background: "rgba(0, 0, 0, 0.5)",
                                                    display: "inline-block",
                                                    padding: "12px 24px",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                    }}>click me 😊</div>
                                                </>
                                            ) : (
                                                <LazySplineElement />
                                            )
                                        }
                                    </div>
                                </div>
                            </section>
                        </>
                    ) : (
                        <>
                            <section className="header-content">
                                <video width="100%" muted autoPlay loop src={require("../assets/0001-0300.mp4").default} />
                                <h1>{HEADER}</h1>
                            </section>
                        </>
                    )
                }
                <section className="content">
                    {/*<div style={{*/}
                    {/*    display: "inline-block",*/}
                    {/*    position: "absolute", top: 350, left: 0, right: 0, margin: "auto", backgroundColor: "tomato"}}>*/}
                    {/*    BLAH*/}
                    {/*</div>*/}
                    <FeaturesSectionComponent
                        id="services"
                        title="Some of what we can do"
                        description="Get to know us a little bit more with the most popular uses cases we offer"
                    >
                        {
                            features.map((feature, index) => <FeaturesSectionCardComponent key={`feature-${index}`} {...feature} />)
                        }
                    </FeaturesSectionComponent>
                    <FeaturesSectionComponent
                        id="technologies"
                        type={FeaturesSectionType.ITEM}
                        title="Technology Stack"
                        description="We have a ton of experience in those fields and we always open to learn new ones"
                    >
                        {
                            technologies.map((technology, index) => <FeaturesSectionItemComponent key={`technology-${index}`} {...technology} />)
                        }
                    </FeaturesSectionComponent>
                    <FeaturesSectionComponent
                        id="customers"
                        type={FeaturesSectionType.ITEM}
                        title="Customers"
                        description="Startup, corporate, an entrepreneur and everything in between"
                    >
                        <div className="companies" style={{color: "#505050", fill: "#505050"}}>
                            {/* <StaticImage src="../assets/companies/simspace.png" alt="" /> */}
                            {
                                companies.map(asset => 
                                {
                                    if (typeof asset === "string")
                                    {
                                        return (
                                            <img src={asset} alt={asset.substring(0, asset.lastIndexOf("."))} />
                                            // <StaticImage src={asset} alt={asset.substring(0, asset.lastIndexOf("."))} />
                                        );
                                    }
                                    else
                                    {
                                        const Svg = asset;
                                        return (
                                            <Svg />
                                        );
                                    }
                                })
                            }
                        </div>
                    </FeaturesSectionComponent>
                    <FeaturesSectionComponent
                        id="about"
                        type={FeaturesSectionType.ITEM}
                        title="Who We Are"
                        description="We have over a decade of experience in development and attention to details. Worked in many startups and corporates, developed a ton of projects and complex POCs. And we currently based in Tel Aviv 🙃"
                    >
                    </FeaturesSectionComponent>
                    <FeaturesSectionComponent
                        id="contact"
                        type={FeaturesSectionType.ITEM}
                        title="Let's Talk?"
                        description="Let's discuss on your idea and how it can technically achieved"
                    >
                        <a href={`mailto:${MAIL}`}>{MAIL}</a>
                    </FeaturesSectionComponent>
                </section>
            </main>
        </>
    )
}

export default IndexPage
